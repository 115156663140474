

































































































































import { Vue, Component } from "vue-property-decorator";
import { format } from "date-fns";
import { AttendeeDataObject } from "@/types/interfaces";
import eventHub from "@/event-hub";
import ScheduleItem from "@/components/schedule/ScheduleItem.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import svgClock from "@/components/svg/svg-clock.vue";
import {
    initDomObserverForA11yAttrFixes,
    a11yFixBuefyTabsAriaAttrs,
    a11yFixBuefyTabHrefs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

import schedulingVuexModule from "@/store/vuex-modules/scheduling";
const schedulingStore = getModule(schedulingVuexModule);

@Component({
    components: {
        ScheduleItem,
        Spinners,
        PageTitleHeader,
        svgClock,
        AdSlot
    },
    directives: {
        addbuefytabs: {
            bind: function(el) {
                eventHub.$emit("BuefyTabsBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    }
})
export default class Schedule extends Vue {
    companyName = "";
    isHoverable = false;
    isStriped = false;
    activeTab = 0;
    isLoading = true;
    testTime = "";
    buefyTabsObserver = {};

    /**
     * Computed
     */
    get sessionEarlyAccessMinutes() {
        return this.$store.state.settingsVuexModule.sessionEarlyEntryMinutes;
    }

    get appointmentExtraMinutes() {
        const returnValue = this.$store.state.settingsVuexModule
            .speakeasyExtraMinutes;
        return returnValue;
    }

    get getConfigurableLabel() {
        return this.$store.getters.schedulePageLabel;
    }

    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }
    get isDemoData() {
        return !this.$store.getters.awsConfig.scheduleTable;
    }

    get userid() {
        return this.$store.getters.user.id;
    }

    get scheduleData() {
        if (attendeeScheduleStore.scheduleData) {
            return attendeeScheduleStore.scheduleData;
        }
        return [];
    }

    get pageOption() {
        return this.$store.getters.getPageOptions("schedule");
    }

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("BuefyTabsBound", this.handleBuefyTabsObserver);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
        this.$store.dispatch("clearNetworkingNotifications");

        attendeeScheduleStore
            .getAttendeeSchedule()
            .then(() => {
                this.getRsvps();
            })
            .finally(() => {
                this.setScheduleDate();
                this.isLoading = false;
            });
    }

    mounted() {
        // TODO: Work around - a11y fix. Add href so screen readers can pick up tabs.
        // need better solution to adding these attributes.
        setTimeout(function() {
            const tabLinks = document.querySelectorAll(".tabs a");
            [...tabLinks].forEach((item) => {
                const el = item as HTMLAnchorElement;
                if (!el.getAttribute("href")) {
                    el.href = "javascript: void(0);";
                    el.setAttribute(
                        "aria-label",
                        `See schedule for ${el.innerText}`
                    );
                }
            });
        }, 1000);
    }

    updated() {
        const elContainer = document.querySelector(".b-tabs .tabs");
        const activeEl = document.querySelector(
            ".b-tabs .tabs ul li.is-active"
        ) as HTMLUListElement;

        if (elContainer != null && activeEl != null) {
            this.$nextTick(() => {
                const marginLeft = activeEl.offsetLeft;
                const elWidth = activeEl.offsetWidth;
                const screenSize = screen.width;

                if (screenSize <= 1280) {
                    elContainer.scrollLeft =
                        marginLeft - (screenSize / 2 - elWidth / 2);
                } else if (screenSize < 1980) {
                    elContainer.scrollLeft =
                        marginLeft - screenSize / 2 + elWidth;
                } else if (screenSize <= 2400) {
                    elContainer.scrollLeft = marginLeft - screen.width / 4 - 52;
                } else if (screenSize > marginLeft) {
                    elContainer.scrollLeft =
                        marginLeft - screenSize / 4 + elWidth / 2;
                }
            });
        }
    }

    beforeDestroy() {
        eventHub.$off("BuefyTabsBound", this.handleBuefyTabsObserver);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        attendeeScheduleStore.setScheduleAttendees([]);
        schedulingStore.clearRsvps();

        if (this.buefyTabsObserver instanceof MutationObserver) {
            this.buefyTabsObserver.disconnect();
        }
    }

    /**
     * Methods
     */
    getRsvps() {
        const ids = attendeeScheduleStore.rsvpsToGet;
        attendeeStore.getAttendees(ids).then((response) => {
            const x = response as Array<AttendeeDataObject>;
            x.forEach((element: AttendeeDataObject | Error) => {
                if (!(element instanceof Error)) {
                    attendeeScheduleStore.addScheduleAttendee(element);
                }
            });
        });
    }

    parseTimeToISO = (timeStart: string, timeEnd: string) => {
        const newTimeS = Vue.prototype.MgFormatISODateTime(timeStart, "time");
        const newTimeE = Vue.prototype.MgFormatISODateTime(timeEnd, "time");

        return newTimeS + " - " + newTimeE;
    };

    setScheduleDate() {
        if (attendeeScheduleStore.scheduleData) {
            const dateString = format(window.MgServerTime, "yyyy-MM-dd");
            const dateIndex = attendeeScheduleStore.scheduleData.findIndex(
                (item) => {
                    return item.date === dateString;
                }
            );
            if (dateIndex > -1) {
                this.$nextTick(() => {
                    this.activeTab = dateIndex;
                });
            }
        }
    }

    handleBuefyTabsObserver(el: HTMLElement) {
        if (this.buefyTabsObserver instanceof MutationObserver) {
            return;
        }
        this.buefyTabsObserver = initDomObserverForA11yAttrFixes(
            el,
            function() {
                a11yFixBuefyTabsAriaAttrs(el);
                a11yFixBuefyTabHrefs(el);
            }
        );
    }

    handleBuefyNotificationFixes(el: HTMLElement) {
        a11yFixBuefyNotificationAriaAttrs(el);
    }
}
